import "@/app/axios.js"

window.globalJs = {
    ...window.globalJs,
    axios: window.axios,
    route: window.translationsData.global_route,
    translations: window.translationsData.translations,
    toCurrency: (value) => {
        return new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(value)
    },
}
